<template>
    <div>商机分配</div>
  </template>
  
  <script>
  export default {
    name: 'distribution'
  }
  </script>
  
  <style lang="less" scoped></style>